/* eslint-disable jsx-a11y/iframe-has-title */
export const Anatomy = () => {
  return <section className="Anatomy">
    <iframe src="https://www.ypo.education/csc-MjMzOTY0OA==/s-16/c-14/" 
      id="ypoedu-container" 
      name="ypoedu-container" 
      width="100%"
      height="100%"
      style={{
        height: "100vh"
      }}
      border="0px" 
      allowFullScreen="allowfullscreen"></iframe>
  </section>
}